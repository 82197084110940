var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "notifications-demo" } },
    [
      _c("p", { staticClass: "mb-4" }, [
        _vm._v("Nice, simple, and interactive notifications"),
      ]),
      _c("notifications-default"),
      _c("notifications-position"),
      _c("notifications-icon"),
      _c("notifications-fixed-and-function-click"),
      _c("notifications-time"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }