var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To add a notification we have the global function "),
        _c("code", [_vm._v("$vs.notify")]),
        _vm._v(". The first parameter is a "),
        _c("code", [_vm._v("json")]),
        _vm._v(
          " with all the necessary attributes, or if we only want the standard notification we can only add a string with the text of the notification"
        ),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.$vs.notify({
                    title: "Primary",
                    text: "Lorem ipsum dolor sit amet, consectetur",
                    color: "primary",
                  })
                },
              },
            },
            [_vm._v("Notification Primary")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "success", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.$vs.notify({
                    title: "Success",
                    text: "Lorem ipsum dolor sit amet, consectetur",
                    color: "success",
                  })
                },
              },
            },
            [_vm._v("Notification Success")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "danger", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.$vs.notify({
                    title: "Danger",
                    text: "Lorem ipsum dolor sit amet, consectetur",
                    color: "danger",
                  })
                },
              },
            },
            [_vm._v("Notification Danger")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "warning", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.$vs.notify({
                    title: "Warning",
                    text: "Lorem ipsum dolor sit amet, consectetur",
                    color: "warning",
                  })
                },
              },
            },
            [_vm._v("Notification Warning")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "dark", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.$vs.notify({
                    title: "dark",
                    text: "Lorem ipsum dolor sit amet, consectetur",
                    color: "dark",
                  })
                },
              },
            },
            [_vm._v("Notification Dark")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "success", type: "gradient" },
              on: {
                click: function ($event) {
                  return _vm.random()
                },
              },
            },
            [_vm._v("Notification Random Color")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <div class="demo-alignment">\n\n      <vs-button\n        @click="$vs.notify({\n          title:\'Primary\',\n          text:\'Lorem ipsum dolor sit amet, consectetur\',\n          color:\'primary\'})"\n        color="primary"\n        type="flat">Notification Primary</vs-button>\n\n      <vs-button\n        @click="$vs.notify({\n          title:\'Success\',\n          text:\'Lorem ipsum dolor sit amet, consectetur\',\n          color:\'success\'})"\n        color="success"\n        type="flat">Notification Success</vs-button>\n\n      <vs-button\n        @click="$vs.notify({\n          title:\'Danger\',\n          text:\'Lorem ipsum dolor sit amet, consectetur\',\n          color:\'danger\'})"\n        color="danger"\n        type="flat">Notification Danger</vs-button>\n\n      <vs-button\n        @click="$vs.notify({\n          title:\'Warning\',\n          text:\'Lorem ipsum dolor sit amet, consectetur\',\n          color:\'warning\'})"\n        color="warning"\n        type="flat">Notification Warning</vs-button>\n\n      <vs-button\n        @click="$vs.notify({\n          title:\'dark\',\n          text:\'Lorem ipsum dolor sit amet, consectetur\',\n          color:\'dark\'})"\n        color="dark"\n        type="flat">Notification Dark</vs-button>\n\n      <vs-button @click="random()" color="success" type="gradient">Notification Random Color</vs-button>\n    </div>\n</template>\n\n<script>\nexport default {\n  methods: {\n    random() {\n      function getRandomInt(min, max) {\n        return Math.floor(Math.random() * (max - min)) + min;\n      }\n      let color = `rgb(${getRandomInt(0,255)},${getRandomInt(0,255)},${getRandomInt(0,255)})`\n\n      this.$vs.notify({\n        title: \'Color\',\n        text: \'Lorem ipsum dolor sit amet, consectetur\',\n        color: color\n      })\n\n    },\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }