var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Time", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the total time that the notification stays on the screen with the "
        ),
        _c("code", [_vm._v("time")]),
        _vm._v(" property. Example: "),
        _c("code", [_vm._v("time: 4000")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v("By default the notifications have a time of "),
            _c("strong", [_vm._v("2000")]),
            _vm._v(" (2s)"),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.$vs.notify({
                    title: "Time default",
                    text: "Lorem ipsum dolor sit amet consectetur",
                    color: "primary",
                    iconPack: "feather",
                    icon: "icon-clock",
                  })
                },
              },
            },
            [_vm._v("Time default")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "flat" },
              on: {
                click: function ($event) {
                  _vm.$vs.notify({
                    time: 4000,
                    title: "Time 4s (4000)",
                    text: "Lorem ipsum dolor sit amet consectetur",
                    color: "primary",
                    iconPack: "feather",
                    icon: "icon-clock",
                  })
                },
              },
            },
            [_vm._v("Time 4s (4000)")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "flat" },
              on: {
                click: function ($event) {
                  _vm.$vs.notify({
                    time: 8000,
                    title: "Time 8s (8000)",
                    text: "Lorem ipsum dolor sit amet consectetur",
                    color: "primary",
                    iconPack: "feather",
                    icon: "icon-clock",
                  })
                },
              },
            },
            [_vm._v("Time 8s (8000)")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <div class=\"demo-alignment\">\n\n    <vs-button @click=\"$vs.notify({\n        title:'Time default',\n        text:'Lorem ipsum dolor sit amet consectetur',\n        color:'primary',\n        iconPack: 'feather',\n        icon: 'icon-clock'\n        })\" color=\"primary\" type=\"flat\">Time default</vs-button>\n\n    <vs-button @click=\"$vs.notify({\n        time:4000,\n        title:'Time 4s (4000)',\n        text:'Lorem ipsum dolor sit amet consectetur',\n        color:'primary',\n        iconPack: 'feather',\n        icon: 'icon-clock'\n        })\" color=\"primary\" type=\"flat\">Time 4s (4000)</vs-button>\n\n    <vs-button @click=\"$vs.notify({\n        time:8000,\n        title:'Time 8s (8000)',\n        text:'Lorem ipsum dolor sit amet consectetur',\n        color:'primary',\n        iconPack: 'feather',\n        icon: 'icon-clock'\n        })\" color=\"primary\" type=\"flat\">Time 8s (8000)</vs-button>\n  </div>\n</template>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }