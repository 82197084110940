var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Fixed and function click", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "Sometimes we need to execute a function when the user clicks on the notification. For this, pass a function to the click property. Example: "
        ),
        _c("code", [_vm._v("click:()=>{ ... }")]),
      ]),
      _c(
        "vs-button",
        {
          staticClass: "mt-4",
          attrs: { type: "gradient", color: "success" },
          on: {
            click: function ($event) {
              return _vm.myFunction()
            },
          },
        },
        [_vm._v("Run Example")]
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <vs-button @click=\"myFunction()\" type=\"gradient\" color=\"success\" class=\"my-4\">Run Example</vs-button>\n</template>\n\n<script>\nexport default {\n  methods: {\n    myFunction() {\n      this.$vs.notify({\n        title: 'Function click',\n        text: 'Click on me to execute function',\n        color: 'primary',\n        fixed: true,\n        click: () => {\n          // Secondary function\n          this.$vs.notify({\n            title: 'Secondary function',\n            text: 'Executed the function when clicking',\n            color: 'success',\n            iconPack: 'feather',\n            icon: 'icon-check'\n          })\n        },\n      })\n    },\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }